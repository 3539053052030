<template>
    <div class="row">
    <div class="col-12 skeleton-card" style="height: 40px; display: flex; align-items: center;">
        <div class="skeleton" style="height: 20px; border-radius: 8px; width: 30%;"></div>
    </div>
    <div class="col-12">
      <div class="row" style="margin-top: 40px;">
          <div class="col-lg-3 col-md-6 pt-3 pb-3" v-for="(_, index) in Array(4).fill(null)" :key="index">
            <div class="skeleton-card d-flex align-items-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-6"> 
                        <div class="skeleton skeleton-image"></div>
                      </div>
                      <div class="col-6">
                        <div class="skeleton skeleton-text"></div>
                        <div class="skeleton skeleton-text"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="skeleton skeleton-text" style="width: 50%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-end mt-4">
      <div class="skeleton-card" style="width: 15%; height: 40px; display: flex; align-items: center; padding-inline: 16px;">
        <div class="skeleton skeleton-text" style="width: 100%;"></div>
      </div>
      <div class="skeleton-card" style="width: 15%; height: 40px; display: flex; align-items: center; padding-inline: 16px; margin-left: 16px;">
        <div class="skeleton skeleton-text" style="width: 100%;"></div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end mt-4">
        <div class="skeleton skeleton-text" style="width: 10%;"></div>
    </div>
    <div class="col-12 mt-4">
      <div class="skeleton-card" style="width: 100%; height: 300px; padding-inline: 16px;">
        <div class="row mt-4">
          <div class="col-6">
            <div class="skeleton skeleton-text" style="width: 50%; height: 20px;"></div>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <div class="skeleton skeleton-text" style="width: 20%; height: 20px;"></div>
            <div class="skeleton skeleton-text" style="width: 20%; height: 20px; margin-left: 16px;"></div>
          </div>
          <div class="col-12 mt-4">
            <div class="skeleton skeleton-text" style="width: 70%; height: 20px;"></div>
          </div>
          <div class="col-12 mt-4">
            <div class="skeleton skeleton-text" style="width: 50%; height: 20px;"></div>
          </div>
          <div class="col-12 mt-4">
            <div class="skeleton skeleton-text" style="width: 30%; height: 20px;"></div>
          </div>
          <div class="col-12 mt-4 d-flex justify-content-between">
            <div class="skeleton skeleton-text" style="width: 10%; height: 20px;"></div>
            <div class="skeleton skeleton-text" style="width: 10%; height: 20px;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div class="skeleton-card" style="width: 100%; height: 400px; padding-inline: 16px;">
        <div class="row mt-4">
          <div class="col-12">
            <div class="skeleton skeleton-text" style="width: 10%; height: 20px;"></div>
          </div>
          <div class="col-12 mt-2">
            <div class="skeleton skeleton-text" style="width: 20%; height: 20px;"></div>
          </div>
          <div class="col-12 mt-4">
            <div class="row">
              <div class="col-3">
                <div class="skeleton skeleton-text" style="width: 100%; height: 20px;"></div>
              </div>
              <div class="col-3">
                <div class="skeleton skeleton-text" style="width: 100%; height: 20px;"></div>
              </div>
              <div class="col-3">
                <div class="skeleton skeleton-text" style="width: 100%; height: 20px;"></div>
              </div>
              <div class="col-3">
                <div class="skeleton skeleton-text" style="width: 100%; height: 20px;"></div>
              </div>
              <div v-for="n in 4" :key="n" class="col-12 mt-2">
              <div class="row">
                <div class="col-3">
                  <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
                </div>
                <div class="col-3 d-flex flex-column">
                  <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
                  <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
                </div>
                <div class="col-3">
                  <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
                </div>
                <div class="col-3">
                  <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <div class="row">
              <div class="col-6">
                <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div class="skeleton skeleton-text" style="width: 50%; height: 10px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'skeleton-dashboard',
}
</script>

<style>
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-card {
  border: 1px solid var(--card-border-color);
  border-radius: 8px;
  background-color: var(--background-color-secondary);
  box-shadow: 0 0 0 1px rgba(0,0,0,.05);
  height: 150px;
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-image {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 50%);
  }
  100% {
    background-color: hsl(200, 20%, 80%);
  }
}
</style>