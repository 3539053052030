<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard :finishButtonShow="btnFinishShow" :finishButtonText="btnFinishTxt" @finishstep="finishstep()">
          <template slot="header">
            <h2 >Build your profile</h2>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-single-02"></i>
              <p>Personal Profile</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-badge"></i>
              <p>Business Profile</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')" v-if="!isAdmin">
            <template slot="label">
              <i class="tim-icons icon-bank"></i>
              <p>Payment Setup</p>
            </template>
            <third-step 
              ref="step3"
              @on-validated="onStepValidated"
            ></third-step>
          </wizard-tab>

          <!-- <wizard-tab :before-change="() => validateStep('step4')" v-if="!questionnaireCompleted">
            <template slot="label">
              <i class="fad fa-clipboard-list"></i>
              <p>questionnaire</p>
            </template>
            <fourth-step 
              ref="step4"
              @on-validated="onStepValidated"
            ></fourth-step>
          </wizard-tab> -->

          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
              <i class="fas fa-medal"></i>
              <p>Finish Setup</p>
            </template>
            <finish-step 
              ref="step5"
              @on-validated="onStepValidated"
            ></finish-step>
          </wizard-tab>

        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './Includes/UserSetup/FirstStep.vue';
import SecondStep from './Includes/UserSetup/SecondStep.vue';
import ThirdStep from './Includes/UserSetup/ThirdStep.vue';
//import FourthStep from './Includes/UserSetup/FourthStep.vue';
import FinishStep from './Includes/UserSetup/FinishStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';

export default {
  data() {
    return {
      wizardModel: {},
      isAdmin:false,
      questionnaireCompleted:false,
      userdata: [],
      btnFinishShow:true,
      initialCheck:true,
      btnFinishTxt: 'Go To General Settings'
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    //FourthStep,
    FinishStep,
    SimpleWizard,
    WizardTab
  },
  methods: {
    validateStep(ref) {
      //console.log(ref);
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      //console.log(validated.FullName);
      this.wizardModel = { ...this.wizardModel, ...model };
      //console.log(this.wizardModel);
      //console.log(this.wizardModel.FullName);
    },
    finishstep() {
      //swal('Good job!', 'You clicked the finish button!', 'success');
      //alert('God Bless You!');
      this.initialCheck = false;
      this.CheckRoleModule();
    },
    CheckRoleModule() {              
      //console.log(user)
      var companyID = this.userdata.company_id;
      var roleid = this.userdata.role_id;
      this.userType = this.userdata.user_type;
      this.$sidebar.displaySidebar(true);
      
      if(this.userType == 'client') {
        this.$store.dispatch('checkUserSetupComplete', {
              usrID: this.userdata.id,
          }).then(response => {
              this.$global.checkClientModule(response.setupcomplete,response.accessmodule);
              //console.log(response);
              if(response.accessmodule['leadspeek']) {
                if(!this.initialCheck) {
                  var lptype = response.accessmodule['leadspeek_type'];
                  lptype = lptype.split('|');
                  for(var i=0;i<lptype.length;i++) {
                    if (lptype[i] == 'local') {
                      document.location = '/' + this.userdata.leadlocalurl + '/dashboard';
                      return
                    }else{
                      document.location = '/' + this.userdata.leadlocatorurl + '/dashboard';
                    }
                  }
                  //this.$router.push({ name: this.userdata.leadlocalname });
                }else{
                  //console.log('here');
                  $('#finishheader').html('Thank you for completing your profile!');
                  //$('#finishsubheader').html('Click "Go to Dashboard" button to start using the application.');
                }
              }else{
                //console.log('there');
                //this.btnFinishShow = false;
              }
          },error => {
              
          });
      }else{
        this.$store.dispatch('GetRoleList', {
              companyID: companyID,
              getType:'getrolemodule',
              roleID:roleid,
              usrID: this.userdata.id,
          }).then(response => {
              //console.log(user);
              //this.rolemodulelist = response;
              //this.checkModuleRole(response);
              this.$global.checkModuleRole(response.modules,response.setupcomplete);
              if(!this.initialCheck) {
                //this.$router.push({ name: 'LeadSpeek' })
                this.$router.push({name: 'General Setting'})
              }else{
                  $('#finishheader').html('Thank you for completing your profile!');
                  //$('#finishsubheader').html('Click "Go to Dashboard" button to start using the application.');
              }
          },error => {
              
          });
      }

    },
  },
  mounted() {
    this.userdata = this.$store.getters.userData
    this.CheckRoleModule();
    //this.isAdmin = false;
    //console.log(this.userdata);
    this.isAdmin = false;
    this.questionnaireCompleted = true;
    if ((this.userdata.isAdmin == 'T' && this.userType == 'user') || this.userdata.customer_payment_id != '') {
      this.isAdmin = true;
    }
    if(this.userdata.questionnaire_setup_completed == 'T' || this.userdata.isAdmin == 'T' || this.userdata.user_type == 'user' || this.userdata.user_type == 'userdownline' || this.userdata.disable_client_add_campaign == 'T') {
      this.questionnaireCompleted = true;
    }

    if (this.userType == 'client') {
      this.btnFinishTxt = 'Go To Dashboard';
    }else{
      this.btnFinishTxt = 'Go To General Settings';
    }
    
  }
};

</script>
<style type="text/css">
 .card-wizard .nav-pills .nav-item .nav-link p {
   width: 120px;
 }
</style>
