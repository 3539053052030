<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate" id="usrsetupcc">
      <div class="row justify-content-center centerform">
        <div class="col-sm-12" v-if="!agencyManualBill">
          <h5 class="info-text">Your Billing Information</h5>
          <h5 v-if="this.emmplatform">Terms and conditions apply. <br/>60 days free, then 12 month plan of minimum $149/month spend.</h5>
        </div>
        
        <div v-if="!agencyManualBill" id="card-element" class="col-sm-12 col-md-12 col-lg-12 card-border">&nbsp;</div> <div class="pt-2 pl-2" v-if="cardfailetoload" style="font-size:0.80143rem"><i class="fas fa-sync-alt pr-2" @click="refreshCCfield();" style="cursor:pointer"></i>Failed to load the Credit Card field. Please <a href="javascript:void(0);" @click="refreshCCfield();" style="font-weight:bold">click here</a> to refresh and try again.</div>
        <div v-if="!agencyManualBill" id="carderror" class="col-sm-12 col-md-12 col-lg-12 pt-2 hide" style="color:red"><small>&nbsp;</small></div>

        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-3">
          <ValidationProvider
            name="Card Holder Name"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="cardholdername"
            label="Card Holder Name"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
                <base-radio name="sameCompanyAddr" v-model="radios.billaddresssame" @change="chksameaddresswith" inline>Use company mailing address for the billing address.</base-radio>
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
                <base-radio name="sameCustAddr" v-model="radios.billaddresssame" @change="chksameaddresswith" inline>Use personal mailing address for the billing address.</base-radio> 
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
          <ValidationProvider
            name="billingAddress"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="billingaddress"
            label="Billing Address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 mt-2">
            <div class="country-region-select-wrapper">
              <span  class="country-label">Country *</span>
              <country-select  class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
              <span v-if="showErrorMessage && !selects.country" style="color: red;">Please select country</span>
            </div> 
         </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 mt-2">
          <div v-if="selects.country" class="country-region-select-wrapper">
          <span class="country-label">{{ selects.country === "CA" ? "Province *" : "State *" }}</span>
          <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state"  />
          <span v-if="showErrorMessage && !selects.state" style="color: red;">Please select state</span>
          </div>
        </div>
       
          <div v-if="!agencyManualBill" class="col-sm-6 col-md-6 col-lg-6 pt-2">
              <ValidationProvider
                name="City"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
                <base-input
                  v-model="city"
                  label="City"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
          <!-- <div class="col-sm-4 col-md-4 col-lg-4 pt-2">
             <label>State</label>
             <base-input>
                <el-select
                  id="stateid"
                  v-model="selects.state"
                  class="select-primary "
                  name="state"
                  inline
                  size="large"
                  filterable
                  default-first-option
                  >
                  <el-option
                    v-for="option in selects.statelist"
                    class="select-primary"
                    :label="option.state"
                    :value="option.state_code"
                    :key="option.state_code"
                  ></el-option>
                </el-select>
             </base-input>
          </div> -->
          <div v-if="!agencyManualBill" class="col-sm-6 col-md-6 col-lg-6 pt-2">
            <ValidationProvider
                name="zipcode"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
              <base-input
                  v-model="zipcode"
                  label="Zip"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
        

        <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                <div class="or-container">
                    <div class="line-separator"></div>
                    <div class="or-label">Service and Billing Agreement</div>
                    <div class="line-separator"></div>
                </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="termsx" id="style-2" ref="termsandcond" @scroll="onScroll">
            <!-- SERVICE BILLING AGREEMENT -->
              <div v-if="emmplatform" class="container">
                <p class="text-center"><strong>Exact Match Marketing White Label Platform<br />&nbsp;Services Agreement</strong></p>
                <p><br/></p>
                <p class="text-center"><strong><u>IMPORTANT &ndash; READ CAREFULLY</u></strong></p>
                <p><strong>THIS SERVICES AGREEMENT (ALONG WITH THE ORDER FORM, THE TERMS OF USE, AND THE PRIVACY POLICY, COLLECTIVELY THIS &ldquo;AGREEMENT&rdquo;), IS BY AND BETWEEN EXACT MATCH MARKETING INC., AN OHIO COMPANY (&ldquo;EXACT MATCH&rdquo;) AND THE ENTITY OR INDIVIDUAL ON WHOSE BEHALF THE SERVICES (AS DEFINED BELOW) ARE BEING ACCESSED OR USED (&ldquo;CUSTOMER&rdquo;). EACH MAY BE REFERRED TO HEREIN COLLECTIVELY AS THE &ldquo;PARTIES&rdquo; OR EACH INDIVIDUALLY AS A &ldquo;PARTY.&rdquo;&nbsp;</strong></p>
                <p><strong>BY INDICATING ACCEPTANCE OF THIS AGREEMENT, EITHER BY CLICKING ON THE &ldquo;AGREE&rdquo; BUTTON, DURING THE ACCOUNT SETUP PROCESS OR USING THE SERVICES, (&ldquo;EFFECTIVE DATE&rdquo;) YOU ARE AGREEING THAT CUSTOMER IS BOUND BY AND USE OF THE SERVICES ON BEHALF OF THE CUSTOMER IS GOVERNED BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF CUSTOMER DOES NOT ACCEPT THIS AGREEMENT, CUSTOMER MAY NOT ACCESS OR USE THE SERVICES.</strong></p>
                <p><br/></p>
                <p><strong>RECITALS </strong></p>
                <p><br/></p>
                <p>WHEREAS, Exact Match has developed a proprietary software offering services to generate leads and disseminate and track advertising and marketing materials that can be used to grow specific needs of a business, and</p>
                <p><br/></p>
                <p>WHEREAS, Customer wishes to subscribe to and license Exact Match&rsquo;s software as a white label Platform to authorized users pursuant to this Agreement, and&nbsp;</p>
                <p><br/></p>
                <p>NOW, THEREFORE, acknowledging the receipt of adequate consideration and intending to be legally bound, the Parties agree as follows:</p>
                 <p><br/></p>
                <p class="bold">1. Services.</p>
                <p><br/></p>
                <p><strong>1.1 </strong>&nbsp;&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted by the Parties.&nbsp;</p>
                <p><br/></p>
                <p><strong>1.2 </strong>&ldquo;Platform&rdquo; means&nbsp;the proprietary software-as-a-service managed by Exact Match, in both source code and object code form, components, documentations, implementation, integration and improvements thereof, provided to Customer&nbsp;as described in the Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to Customer or any Authorized User (as defined below) in connection with the foregoing.</p>
                <p><br/></p>
                <p><strong>1.3 </strong>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including Exact Match&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that Exact Match provides to Customer through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
                <p><br/></p>
                <!--<p><strong>1.4 </strong>Customer shall be entitled to a trial period of sixty (60) days commencing on the Effective Date at no charge (the &ldquo;Free Trial Period&rdquo;). At any time prior to expiration of the Free Trial Period, Customer may elect to cancel this Agreement by providing written notice to Exact Match (&ldquo;Trial Cancellation Notice&rdquo;). If Customers does not provide the Trial Cancellation Notice, then Customer agrees to the terms the Agreement and to pay all applicable charges according to the Agreement.</p>
                <p><br/></p>-->
                <p><strong>1.4 </strong>The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means anyone who Customer gives permission to access and use the Platform in accordance with this Agreement.&nbsp;</p>
                <p><br/></p>
                <p><strong>1.5 </strong>Customer may access the Services only to the extent authorized by Exact Match. Customer is responsible for use of Services by its Authorized Users and other users, whether or not authorized, that access or use the Services using the accounts or credentials of Customer or any Authorized User, and Customer will be liable for any action or omission of any of the preceding that would violate this agreement as if such action or omission were taken by Customer itself. Customer shall ensure that Authorized Users comply with Exact Match&rsquo;s Terms of Use, found at&nbsp;https://exactmatchmarketing.com/index.php/terms-of-use/, (the &ldquo;Terms of Use&rdquo;) which are incorporated herein by reference, when using the Services. Customer acknowledges that it has read and agrees to be bound by the Terms of Use and will properly describe to Authorized Users all capabilities and limitations of the Platform. Customer is responsible for Customer&rsquo;s and its Authorized User&rsquo;s actions and the contents of its transmissions through the Services. These Authorized Users, whether located in or outside of the U.S., and their usage of the Platform are Customer&rsquo;s sole responsibility, and Customer must comply with all applicable laws and security protocols pertaining to their access, including applicable export laws, restrictions, and regulations. For the avoidance of doubt, the Platform should not be made available or copied/distributed to prohibited countries, persons or entities, as indicated in an Office of Foreign Assets Control (OFAC) of the US Department of the Treasury list.</p>
                <p><br/></p>
                <p><strong>1.6 </strong>Customer may not, and will ensure that its Authorized User&rsquo;s do not: (a) duplicate, disassemble, decompile, reverse engineer, or otherwise reproduce without authorization any portion of the Services or source code for the Platform; (b) resell, sublicense or allow any third party (other than Authorized Users, for whom Customer shall be wholly responsible) to use the Services, except pursuant to the normal operation of the Platform; (c) scrape, steal, or copy without authorization the Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party the Services in violation of this Agreement; (f) build a product, service, or offering that competes with Exact Match or Services; (g) use the name or proprietary logo(s) of Exact Match without Exact Match&rsquo;s prior written consent; (h) use the Services for any purpose other than the operation of the Platform; or (i) use the Services in a manner that interferes with the use of the Platform by Exact Match, its other customers.</p>
                <p><br/></p>
                <p><strong>1.7 </strong>Customer may not use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses, spyware or similar computer programming routines, or unsolicited mail; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
                <p><br/></p>
                <p class="bold">2. Customer Responsibilities.</h1>
                <p><br/></p>
                <p><strong>2.1 </strong>This Agreement and Services are a valuable trade secret and confidential proprietary property of Exact Match. Customer agrees to access and use the Services only as provided in this Agreement and to safeguard Exact Match&rsquo;s trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed otherwise in writing.</p>
                <p><br/></p>
                <p><strong>2.2 </strong>Customer is hereby provided with limited access to the Services subject to this Agreement. Subject to the terms of this Agreement, Exact Match hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term stated in the applicable Order Form to access and use the Materials for its ordinary business purposes, (the &ldquo;License&rdquo;). Except as otherwise stated in this section or on the Order Form, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s License to use the Materials is only valid for six (6) months from purchase (or less if required by Exact Match data providers, as set forth in more detail in the Order Form).&nbsp;</p>
                <p><br/></p>
                <p><strong>2.3 </strong>Customer shall: (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
                <p><br/></p>
                <p><strong>2.4 </strong>&ldquo;Customer Content&rdquo; means all content, information, or data that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order Form, Customer hereby grants Exact Match a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve Exact Match&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
                <p><br/></p>
                <p><strong>2.5 </strong>Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to, Exact Match, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to Exact Match.</p>
                <p><br/></p>
                <p><strong>2.6 </strong>Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; (c) complying with applicable law; and (d) the compliance of Customer Content with this Agreement, including content uploaded by its Authorized Users.</p>
                <p><br/></p>
                <p><strong>2.7 </strong>Use of the Platform by Customer&rsquo;s Authorized Users is also subject to Platform Terms of Use and Platform Privacy Policy between Customer and Authorized Users, and Customer agrees that such Platform Terms of Use and Platform Privacy Policy will: (i) be included as at least the minimum end-user terms; and (ii) will be accepted by each Authorized User prior to their use of the Platform. Customer shall ensure that all Authorized Users are aware of: (a) this Agreement&rsquo;s provisions as applicable to the Authorized Users of the Platform; and (b) the Platform Terms of Use and the Platform Privacy Policy and their applicability to the Authorized Users of the Platform, and shall ensure that the Authorized Users comply with such provisions of this Agreement and the Terms of Use and Privacy Policy.</p>
                <p><br/></p>
                <p class="bold">3. Charges, Taxes, and Payment.</p>
                <p><br/></p>
                <p><strong>3.1 </strong>Customer agrees to spend a minimum $149 per month during the term of this Agreement, (the &ldquo;Minimum Purchase Commitment&rdquo; or &ldquo;MPC&rdquo;), in accordance with the terms of this Agreement. If Customer fails to purchase a minimum $149 per month to meet the MPC in any given month, then Customer shall be invoiced according to the Agreement an amount equal to the shortfall.</p>
                <p><br/></p>
                <p><strong>3.2 </strong>Customer shall pay all applicable charges and fees specified for the Services&nbsp;under this Agreement as selected by Customer within the Platform for the selected Services and any charges imposed by any authority resulting from Customer's use of the Services, including any applicable sales taxes. The charges described in this Agreement and the Order Form are exclusive of any federal, state, municipal, or foreign taxes, levies, duties or other governmental assessments of any nature, including excise, sales, use, value-added, gross receipts, personal property, occupational, or other taxes (collectively, &ldquo;Taxes&rdquo;) in the United States or applicable countries. Customer is responsible for paying all Taxes associated with its purchases hereunder, excluding taxes based on Exact Match&rsquo;s income or property.</p>
                <p><br/></p>
                <p><strong>3.3 </strong>Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and the Order Form through Exact Match&rsquo;s third-party payment processor. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
                <p><br/></p>
                <p><strong>3.4 </strong>All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in this Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in this Agreement.</p>
                <p><br/></p>
                <p><strong>3.5 </strong>Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date. Exact Match may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
                <p><br/></p>
                <p class="bold">4. Confidentiality.</p>
                <p><br/></p>
                <p><strong>4.1 </strong>&ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>4.2 </strong>Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify Exact Match in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
                <p><br/></p>
                <p><strong>4.3 </strong>Exact Match shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with this Agreement and its policies.</p>
                <p><br/></p>
                <p><strong>4.4 </strong>During the term of this Agreement and at all times thereafter in which Confidential Information remains confidential, neither Party shall disclose Confidential Information of the other Party or use such Confidential Information for any purpose other than in furtherance of this Agreement. Without limiting the preceding sentence, each party shall use at least the same degree of care in safeguarding the other Party&rsquo;s Confidential Information as it uses to safeguard its own Confidential Information. Notwithstanding the foregoing a Party may disclose Confidential Information (a) if required to do so by legal process (i.e., by a subpoena), provided that such Party shall notify the other Party prior to such disclosure so that such other Party may attempt to prevent such disclosure or seek a protective order; or (b) to any applicable governmental authority as required in the operation of such Party&rsquo;s business, and even then, limited to no more than the minimum information required.</p>
                <p><br/></p>
                <p class="bold">5. Ownership of Intellectual Property and Data.</p>
                <p><br/></p>
                <p><strong>5.1 </strong>Exact Match is the exclusive owner of the Platform and Services, and all of the intellectual property associated with its Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by Customer, even if Exact Match incorporates suggestions made by Customer. Customer shall not receive any form of compensation for ideas, modifications, suggestions, or improvements made to Exact Match&rsquo;s Platform or Services. Except as expressly set forth in this Agreement, Customer is granted no license or other rights in or to any Exact Match data or any use thereof.&nbsp;</p>
                <p><br/></p>
                <p><strong>5.2 </strong>Customer shall own and retain all right, title and interest in and to any data, information, and other content, including any text, files, images, graphics, illustrations, information, data, audio, video, photographs, completed program performance and analytics provided by Customer or Customer&rsquo;s Authorized Users. (&ldquo;Customer Content&rdquo;).</p>
                <p><br/></p>
                <p><strong>5.3 </strong>Customer grants Exact Match all rights and licenses in and to the Customer Content as may be useful or reasonably necessary for Exact Match to provide the Services and perform its other obligations under this Agreement. Customer has all rights in and to all Customer Content necessary to permit Exact Match to provide the Services and perform its other obligations under this Agreement.&nbsp;</p>
                <p><br/></p>
                <p class="bold">6. White-Label Content and Branding.</p>
                <p><br/></p>
                <p><strong>6.1 </strong>&nbsp;&ldquo;White-Label Content&rdquo; means any Customer Content that Customer provides to the Platform, as new or replacement material for the purpose of white labeling Customer&rsquo;s instance of the Platform.</p>
                <p><br/></p>
                <p><strong>6.2 </strong>Platform branding includes incorporation of Customer&rsquo;s Data including Customer&rsquo;s name and logo and skinning of the site in Customer&rsquo;s selected primary and secondary colors. The standard Platform URL will appear with Customer&rsquo;s selected word as a subdomain of Exact Match (e.g. Name.exactmatchmarketing.com) unless Customer requests a custom URL. Custom or &lsquo;vanity&rsquo; URLs, which shall include provision of a Secure Socket Layer (SSL), are available upon request. Customer is responsible for clearing any privacy rights, copyright, trademark or other intellectual property concerns connected with a custom URL as well as securing the URL via a registrar service.</p>
                <p><br/></p>
                <p><strong>6.3 </strong>Customer agrees to collaborate with Exact Match on initial implementation of White-Label Content. Customer will be responsible for editing and maintaining the White-Label Content after this initial implementation. White-Label Content changes may include customizing setup categories, tasks, content, related resources, assessments, and other content changes that fit within the Platform&rsquo;s existing functions, as may be identified and changed by Exact Match from time to time and at Exact Match&rsquo;s sole discretion.</p>
                <p><br/></p>
                <p><strong>6.4 </strong>After the initial implementation, Customer shall ensure that no Authorized Users remove, delete, alter, or obscure any trademarks, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Platform without the prior written consent of Exact Match.&nbsp;A copyright notice with Exact Match&rsquo;s name and Terms of Use, provided at https://exactmatchmarketing.com/index.php/terms-of-use/, shall appear on the Platform.</p>
                <p><br/></p>
                <p><strong>6.5 </strong> Customer acknowledges that charged or invoiced fees as stated in the Agreement and Order Form may include additional costs related to support, training, and other services related to the Platform and the Services. Unless otherwise provided in an Order Form, such additional support, training, and other services will be invoiced to Customer on a time and materials basis, at Exact Match&rsquo;s then-standard hourly rates.</p>
                <p><br/></p>
                <p class="bold">7. Warranties.</p>
                <p><br/></p>
                <p>Each party warrants to the other that this Agreement has been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
                <p><br/></p>
                <p class="bold">8. Warranty Disclaimer.</p>
                <p><br/></p>
                <p>THE SERVICES PROVIDED ARE &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDERS ASSURES OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT EXACT MATCH MARKETING WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE.</p>
                <p><br/></p>
                <p class="bold">8. Indemnity.</p>
                <p><br/></p>
                <p><strong>8.1 </strong>Customer shall defend, indemnify, and hold Exact Match, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to this Agreement, including, without limitation, any claims relating to the Services and Customer Content.</p>
                <p><br/></p>
                <p><strong>8.2 </strong>Exact Match shall defend, indemnify, and hold Customer harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties that arises from Exact Match&rsquo; provision of the Services in an allegedly unlawful manner or in violation of this Agreement.&nbsp;</p>
                <p><br/></p>
                <p><strong>8.3 </strong>If a third party asserts a claim against Customer that the Services offered by Exact Match infringes a patent, copyright, or trademark, Exact Match will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by Exact Match, provided that Customer promptly (a) notifies Exact Match in writing of the claim, (b) supplies information requested by Exact Match, and (c) allows Exact Match to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
                <p><br/></p>
                <p><strong>8.4 </strong>Exact Match&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by Exact Match; or (b) Customer&rsquo;s unauthorized modifications to the Services; or (c) Customer&rsquo;s own Customer Content.</p>
                <p><br/></p>
                <p><strong>8.5 </strong>This Section describes Customer&rsquo;s sole remedy against Exact Match relating to third-party claims of patent, copyright, or trademark infringement.</p>
                <p><br/></p>
                <p class="bold">9. Limitation of Liability.</p>
                <p><br/></p>
                <p><strong>10.1.</strong> WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW, EXACT MATCH MARKETING'S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO EXACT MATCH MARKETING, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
                <p><strong>10.2</strong>. THE LIMITATIONS IN SECTION 10.1 WILL NOT APPLY TO: (1) EITHER PARTY&rsquo;S BREACH OF CONFIDENTIALITY; (2) THE PARTIES&rsquo; INDEMNIFICATION OBLIGATIONS; (3) CUSTOMER&rsquo;S VIOLATION OF SECTION 1 (SERVICES) OR SECTION 2 OF THIS AGREEMENT (CUSTOMER RESPONSIBILITIES); (4) CUSTOMER&rsquo;S INFRINGEMENT OF EXACT MATCH&rsquo;S INTELLECTUAL PROPERTY OR MISAPPROPRIATION; (5) GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
                <p><br/></p>
                <p class="bold">10. Privacy and Security.</p>
                <p><br/></p>
                <p><strong>10.1 </strong>All access to and use of the Services and use of the Platform is subject to Exact Match&lsquo;s Privacy Policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;), a current copy of which is available at https://exactmatchmarketing.com/index.php/privacy-policy/. Customer agrees to comply with the current terms of the Privacy Policy. Exact Match may collect, use, share, and store data made available via the Platform in connection with the Services hereunder and in accordance with Exact Match&rsquo;s policies and applicable laws.</p>
                <p><br/></p>
                <p><strong>10.2 </strong>Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
                <p><br/></p>
                <p><strong>10.3 </strong>Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
                <p><br/></p>
                <p class="bold">11. Changes.</p>
                <p><br/></p>
                <p><strong>11.1 </strong>Exact Match may, at its reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability. Exact Match&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing, Exact Match is not required to modify the Services.</p>
                <p><br/></p>
                <p><strong>11.2 </strong>Exact Match may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
                <p><br/></p>
                <p><strong>11.3 </strong>Except as otherwise provided, all changes to this Agreement must be in writing and signed by both Parties.</p>
                <p><br/></p>
                <p class="bold">12. Term and Termination.</p>
                <p><br/></p>
                <p><strong>12.1 </strong>The term of this Agreement shall commence on the earlier of (a) the date of acceptance of this Agreement on behalf of Customer or (b) the date of first use of the Services on behalf of Customer. Termination of this Agreement by either party automatically terminates all orders in the applicable Order Form.&nbsp;</p>
                <p><br/></p>
                <p><strong>12.2 </strong>Services renew annually and automatically unless Customer provides written notice to Exact Match not to renew at least 60 calendar days prior to the annual renewal.</p>
                <p><br/></p>
                <p><strong>12.3 </strong>Exact Match may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer's use of the Services or this Agreement where Exact Match reasonably suspects a breach of this Agreement. Exact Match shall provide notice of the actions Customer must take to reinstate the Services. Exact Match may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
                <p><br/></p>
                <p><strong>12.4 </strong>Exact Match may terminate an order or this Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
                <p><br/></p>
                <p><strong>12.5 </strong>This Agreement shall automatically terminate upon the liquidation or insolvency of either Party or the appointment of a trustee or receiver for either Party.</p>
                <p><br/></p>
                <p><strong>12.6 </strong>Upon termination of this Agreement or order described in the applicable Order Form: (a) Exact Match Marketing may immediately terminate Customer&rsquo;s access to the Services; and (b) Customer shall immediately cease using any portion of the Services.</p>
                <p><br/></p>
                <p><strong>12.7 </strong>Customer may not terminate this Agreement for convenience, except as otherwise stated in this Agreement. Either party may terminate this Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay Exact Match constitutes a material breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>12.8 </strong>If this Agreement is terminated for any reason (except for a material breach by Exact Match), Customer shall pay to Exact Match, on the date of termination, the total amount outstanding under this Agreement, including any fees for any remaining time period of a subscription or license. If Exact Match terminates this Agreement for a reason other than Customer&rsquo;s material breach, or if this Agreement is terminated for Exact Match&rsquo;s material breach, Exact Match shall provide Customer a pro rata refund for any prepaid and unearned fees.</p>
                <p><br/></p>
                <p><strong>12.9 </strong>After termination of this Agreement, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer. Exact Match may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
                <p><br/></p>
                <p><strong>12.10 </strong> After termination of this Agreement, except as otherwise provided in the applicable Order Form, Exact Match may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants Exact Match a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve Exact Match&rsquo;s Services or for its business purposes.</p>
                <p><br/></p>
                <p class="bold">13. Governing Law.</p>
                <p><br/></p>
                <p><strong>13.1 </strong>This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
                <p><br/></p>
                <p><strong>13.2 </strong>Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under this Agreement.</p>
                <p><br/></p>
                <p><strong>13.3 </strong>Each Party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
                <p><br/></p>
                <p><strong>14. Audit.</strong> Customer hereby agrees that upon reasonable notice and at a mutually agreeable time, Exact Match may periodically audit Customer&rsquo;s books and records relevant to the use of the Services in this Agreement in order to ensure compliance with the terms hereof. The third-party data providers of Exact Match shall be deemed intended third-party beneficiaries of this provision and shall also be entitled to investigate all legitimate reports of abuse or misuse of their Service. Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Services, legal action, and/or referral to federal or state regulatory agencies.</p>
                <p><br/></p>
                <p class="bold">15. General.</p>
                <p><br/></p>
                <p><strong>15.1 </strong>This Agreement constitutes the entire agreement of the Parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the Parties relating to its subject matter.</p>
                <p><br/></p>
                <p><strong>15.2 </strong>In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order Form and this Agreement, the Order Form governs.</p>
                <p><br/></p>
                <p><strong>15.3 </strong>If any provision of this Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
                <p><br/></p>
                <p><strong>15.4 </strong>The waiver of a breach of any term of this Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>15.5 </strong>Customer and Exact Match agree not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, client, customer, supplier, or contractor of either Party during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
                <p><br/></p>
                <p><strong>15.6 </strong>Exact Match is an independent contractor. Customer is responsible for its use of the Services. Each Party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
                <p><br/></p>
                <p><strong>15.7 </strong>Exact Match may collect information relating to Customer&rsquo;s use of the Services. Exact Match may internally use this information for providing or improving the Services.</p>
                <p><br/></p>
                <p><strong>15.8 </strong>Except as otherwise provided herein, Customer may not assign this Agreement, in whole or in part, without the prior written consent of Exact Match. Customer may assign this Agreement with 30 calendar days&rsquo; prior written notice to Exact Match upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of Exact Match. Exact Match may assign this Agreement at its sole discretion. Any assignment, transfer, or delegation of this Agreement in violation of this section is void.</p>
                <p><br/></p>
                <p><strong>15.9 </strong>All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement or to such other address as shall be advised in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid).&nbsp;</p>
                <p><br/></p>
                <p style="margin-left:20px"><strong>Exact Match Marketing, Inc.</strong></p>
                <p style="margin-left:20px">Email: <a href="mailto:josh@exactmatchmarketing.com">josh@exactmatchmarketing.com</a>&nbsp;</p>
                <p style="margin-left:20px">Attention: Josh Rockey</p>
                <p style="margin-left:20px">Address: 895 Dietrich Ct Newark OH 43055</p>
                <p><br/></p>
                <p><strong>15.10 </strong>This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each Party and delivered to the other Party.</p>
                <p><br/></p>
                <p><strong>15.11 </strong>The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement &ldquo;I Agree&rdquo;, pursuant to the Ohio Uniform Electronic Transactions Act (R.C. 1306.01 et seq.) as amended from time to time. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
                <p><br/></p>
                <p><strong>15.12 </strong>This Agreement does not create any third-party rights. Neither Party will bring a legal action arising from, or relating to this Agreement, more than two years after the cause of action arose.</p>
                <p><br/></p>
                <p><strong>15.13 </strong>Any terms that by their nature extend beyond this Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
                <p><br/></p>
                <p><strong>15.14 </strong>Unless Customer requests otherwise in writing in advance, Customer authorizes Exact Match to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
                <p><br/></p>
                <p><strong>15.15 </strong>The Parties hereto are not responsible for failure to fulfill its obligations under this Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
                <p><br/></p>
                <p><strong>15.16 </strong>The Parties hereto are sophisticated, commercial Parties. This Agreement will not be construed against the drafter.</p>
                <p><br/></p>
                <p><strong>15.7 </strong>Parties acknowledge that they have read this Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each Party is authorized to do so.</p>
                <p><br/></p>
              </div>
              <div v-if="platform" class="container">
                <p class="bold text-center">{{this.$global.companyrootname}} White Label Platform<br/>Services Agreement</p>
                <p><br></p>
                <p><br></p>
                <p>This Services Agreement (along with the Order Form, attached as Exhibit and incorporated herein by reference, the Terms of Use, and the Privacy Policy, collectively this &ldquo;Agreement&rdquo;), is by and between  {{this.$global.companyrootname}}., an {{this.$global.companyrootstatename}} company located at {{this.$global.companyrootaddres}}, {{this.$global.companyrootcity}}, {{this.$global.companyrootstatename}} {{this.$global.companyrootzip}} (&ldquo;{{this.$global.companyrootnameshort}}&rdquo;) and the customer (the &ldquo;Customer&rdquo;), identified in the Order Form. Each may be referred to herein collectively as the &ldquo;Parties&rdquo; or each individually as a &ldquo;Party.&rdquo;</p>
                <p><br></p>
                <p>RECITALS</p>
                <p><br></p>
                <p>WHEREAS, {{this.$global.companyrootnameshort}} has developed a proprietary software offering services to generate leads and disseminate and track advertising and marketing materials that can be used to grow specific needs of a business, and</p>
                <p><br></p>
                <p>WHEREAS, Customer wishes to subscribe to and license {{this.$global.companyrootnameshort}}&rsquo;s software as a white label Platform to authorized users pursuant to this Agreement, and&nbsp;</p>
                <p><br></p>
                <p>NOW, THEREFORE, acknowledging the receipt of adequate consideration and intending to be legally bound, the Parties agree as follows:</p>
                <p><br></p>
                <p class="bold">1. Services.</p>
                <p>1.1 &ldquo;Order Form&rdquo; means&nbsp;Exhibit A, as well as any other order form, statement of work, or other ordering document signed and accepted by the Parties.&nbsp;</p>
                <p>1.2 &ldquo;Platform&rdquo; means the software-as-a-Service offerings as described in the Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to Customer or any Authorized User (as defined below) in connection with the foregoing.&nbsp;</p>
                <p>1.3 &ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including {{this.$global.companyrootnameshort}}&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that {{this.$global.companyrootnameshort}} provides to Customer through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
                <p>1.4 The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means anyone who Customer gives permission to access and use the Platform in accordance with this Agreement.&nbsp;</p>
                <p>1.5 Customer may access the Services only to the extent authorized by {{this.$global.companyrootnameshort}}. Customer is responsible for use of Services by its Authorized Users and other users, whether or not authorized, that access or use the Services using the accounts or credentials of Customer or any Authorized User, and Customer will be liable for any action or omission of any of the preceding that would violate this agreement as if such action or omission were taken by Customer itself. Customer shall ensure that Authorized Users comply with {{this.$global.companyrootnameshort}}&rsquo;s Terms of Use, found at&nbsp;https://{{this.$global.companyrootdomain}}/terms-of-use/, (the &ldquo;Terms of Use&rdquo;) which are incorporated herein by reference, when using the Services. Customer acknowledges that it has read and agrees to be bound by the Terms of Use and will properly describe to Authorized Users all capabilities and limitations of the Platform. Customer is responsible for Customer&rsquo;s and its Authorized User&rsquo;s actions and the contents of its transmissions through the Services. These Authorized Users, whether located in or outside of the U.S., and their usage of the Platform are Customer&rsquo;s sole responsibility, and Customer must comply with all applicable laws and security protocols pertaining to their access, including applicable export laws, restrictions, and regulations. For the avoidance of doubt, the Platform should not be made available or copied/distributed to prohibited countries, persons or entities, as indicated in an Office of Foreign Assets Control (OFAC) of the US Department of the Treasury list.</p>
                <p>1.6 Customer may not, and will ensure that its Authorized User&rsquo;s do not: (a) duplicate, disassemble, decompile, reverse engineer, or otherwise reproduce without authorization any portion of the Services or source code for the Platform; (b) resell, sublicense or allow any third party (other than Authorized Users, for whom Customer shall be wholly responsible) to use the Services, except pursuant to the normal operation of the Platform; (c) scrape, steal, or copy without authorization the Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party the Services in violation of this Agreement; (f) build a product, service, or offering that competes with {{this.$global.companyrootnameshort}} or Services; (g) use the name or proprietary logo(s) of {{this.$global.companyrootnameshort}} without {{this.$global.companyrootnameshort}}&rsquo;s prior written consent; (h) use the Services for any purpose other than the operation of the Platform; or (i) use the Services in a manner that interferes with the use of the Platform by {{this.$global.companyrootnameshort}}, its other customers.</p>
                <p>1.7 Customer may not use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses, spyware or similar computer programming routines, or unsolicited mail; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
                <p><br></p>
                <p class="bold">2. Customer Responsibilities.</p>
                <p>2.1 This Agreement and Services are a valuable trade secret and confidential proprietary property of {{this.$global.companyrootnameshort}}. Customer agrees to access and use the Services only as provided in this Agreement and to safeguard {{this.$global.companyrootnameshort}}&rsquo;s &nbsp;trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed otherwise in writing.</p>
                <p>2.2 Customer is hereby provided with limited access to the Services subject to this Agreement. Subject to the terms of this Agreement, {{this.$global.companyrootnameshort}} hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term stated in the applicable Order Form to access and use the Materials for its ordinary business purposes, (the &ldquo;License&rdquo;). Except as otherwise stated in this section or on the Order Form, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s License to use the Materials is only valid for six (6) months from purchase (or less if required by {{this.$global.companyrootnameshort}} data providers, as set forth in more detail in the Order Form).&nbsp;</p>
                <p>2.3 Customer shall: (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
                <p>2.4 &ldquo;Customer Content&rdquo; means all content, information, or data that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order Form, Customer hereby grants {{this.$global.companyrootnameshort}} &nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve {{this.$global.companyrootnameshort}}&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
                <p>2.5 Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to, {{this.$global.companyrootnameshort}}, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to {{this.$global.companyrootnameshort}}.</p>
                <p>2.6 Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; (c) complying with applicable law; and (d) the compliance of Customer Content with this Agreement, including content uploaded by its Authorized Users.</p>
                <p>2.7 Use of the Platform by Customer&rsquo;s Authorized Users is also subject to Platform Terms of Use and Platform Privacy Policy between Customer and Authorized Users, and Customer agrees that such Platform Terms of Use and Platform Privacy Policy will: (i) include at least the minimum end-user terms attached hereto, but not incorporated herein, as&nbsp;Exhibit B&nbsp;and&nbsp;Exhibit C&nbsp;(&ldquo;Minimum Terms&rdquo;); and (ii) will be accepted by each Authorized User prior to their use of the Platform. Customer shall ensure that all Authorized Users are aware of: (a) this Agreement&rsquo;s provisions as applicable to the Authorized Users of the Platform; and (b) the Platform Terms of Use and the Platform Privacy Policy and their applicability to the Authorized Users of the Platform, and shall ensure that the Authorized Users comply with such provisions of this Agreement and the Terms of Use and Privacy Policy.</p>
                <p><br></p>
                <p class="bold">3. Charges, Taxes, and Payment.</p>
                <p>3.1 As defined in the Order Form, Customer agrees to pay all applicable charges specified for the Services as set forth in the Order Form and any charges imposed by any authority resulting from Customer&apos;s use of the Services, including any applicable sales taxes. The charges described in this Agreement and any Order Form are exclusive of any federal, state, municipal, or foreign taxes, levies, duties or other governmental assessments of any nature, including excise, sales, use, value-added, gross receipts, personal property, occupational, or other taxes&nbsp;(collectively, &ldquo;Taxes&rdquo;)&nbsp;in the United States or applicable countries.&nbsp;Customer is responsible for paying all Taxes associated with its purchases hereunder, excluding taxes based on {{this.$global.companyrootnameshort}}&rsquo;s income or property.</p>
                <p>3.2 Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and any Order Form. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
                <p>3.3 All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in this Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in this Agreement.</p>
                <p>3.4 Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date. {{this.$global.companyrootnameshort}} may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
                <p><br></p>
                <p class="bold">4. Confidentiality.</p>
                <p>4.1 &ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of this Agreement.</p>
                <p>4.2 Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify {{this.$global.companyrootnameshort}} in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
                <p>4.3 {{this.$global.companyrootnameshort}} shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with this Agreement and its policies.</p>
                <p>4.4 During the term of this Agreement and at all times thereafter in which Confidential Information remains confidential, neither Party shall disclose Confidential Information of the other Party or use such Confidential Information for any purpose other than in furtherance of this Agreement. Without limiting the preceding sentence, each party shall use at least the same degree of care in safeguarding the other Party&rsquo;s Confidential Information as it uses to safeguard its own Confidential Information. Notwithstanding the foregoing a Party may disclose Confidential Information (a) if required to do so by legal process (i.e., by a subpoena), provided that such Party shall notify the other Party prior to such disclosure so that such other Party may attempt to prevent such disclosure or seek a protective order; or (b) to any applicable governmental authority as required in the operation of such Party&rsquo;s business, and even then, limited to no more than the minimum information required.</p>
                <p><br></p>
                <p class="bold">5. Ownership of Intellectual Property and Data.</p>
                <p>5.1 {{this.$global.companyrootnameshort}} is the exclusive owner of the Platform and Services, and all of the intellectual property associated with its Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by Customer, even if {{this.$global.companyrootnameshort}} incorporates suggestions made by Customer. Customer shall not receive any form of compensation for ideas, modifications, suggestions, or improvements made to {{this.$global.companyrootnameshort}}&rsquo;s Platform or Services. Except as expressly set forth in this Agreement, Customer is granted no license or other rights in or to any {{this.$global.companyrootnameshort}} data or any use thereof.&nbsp;</p>
                <p>5.2 Customer shall own and retain all &nbsp;right, title and interest in and to any data, information, and other content, including any text, files, images, graphics, illustrations, information, data, audio, video, photographs, completed program performance and analytics provided by Customer or Customer&rsquo;s Authorized Users. (&ldquo;Customer Content&rdquo;).</p>
                <p>5.3 Customer grants {{this.$global.companyrootnameshort}} all rights and licenses in and to the Customer Content as may be useful or reasonably necessary for {{this.$global.companyrootnameshort}} to provide the Services and perform its other obligations under this Agreement. Customer has all rights in and to all Customer Content necessary to permit {{this.$global.companyrootnameshort}} to provide the Services and perform its other obligations under this Agreement.&nbsp;</p>
                <p><br></p>
                <p class="bold">6. White-Label Content and Branding.</p>
                <p>6.1 &ldquo;White-Label Content&rdquo; means any Customer Content that Customer provides to the Platform, as new or replacement material for the purpose of white labeling Customer&rsquo;s instance of the Platform.</p>
                <p>6.2 Platform branding includes incorporation of Customer&rsquo;s Data including Customer&rsquo;s name and logo and skinning of the site in Customer&rsquo;s selected primary and secondary colors. The standard Platform URL will appear with Customer&rsquo;s selected word as a subdomain of {{this.$global.companyrootnameshort}} (e.g. Name.exactmatchmarketing.com) unless Customer requests a custom URL. Custom or &lsquo;vanity&rsquo; URLs, which shall include provision of a Secure Socket Layer (SSL), are available upon request. Customer is responsible for clearing any privacy rights, copyright, trademark or other intellectual property concerns connected with a custom URL as well as securing the URL via a registrar service.</p>
                <p>6.3 Customer agrees to collaborate with {{this.$global.companyrootnameshort}} on initial implementation of White-Label Content. Customer will be responsible for editing and maintaining the White-Label Content after this initial implementation. &nbsp;White-Label Content changes may include customizing setup categories, tasks, content, related resources, assessments, and other content changes that fit within the Platform&rsquo;s existing functions, as may be identified and changed by {{this.$global.companyrootnameshort}} from time to time and at {{this.$global.companyrootnameshort}}&rsquo;s sole discretion.</p>
                <p>6.4 After the initial implementation, Customer shall ensure that no Authorized Users remove, delete, alter, or obscure any trademarks, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Platform without the prior written consent of {{this.$global.companyrootnameshort}}.&nbsp;A copyright notice with {{this.$global.companyrootnameshort}}&rsquo;s name and Terms of Use, provided at https://{{this.$global.companyrootdomain}}/terms-of-use/, shall appear on the Platform.
                <p>6.5 Customer acknowledges that charged or invoiced fees as stated in the Order Form may include additional costs related to support, training, and other services related to the Platform and the Services. &nbsp;Unless otherwise provided in an Order Form, such additional support, training, and other services will be invoiced to Customer on a time and materials basis, at {{this.$global.companyrootnameshort}}&rsquo;s then-standard hourly rates. &nbsp;</p>
                <p><br></p>
                <p class="bold">7. Warranties.</p>
                <p>7.1 Each party warrants to the other that this Agreement has been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
                <p><br></p>
                <p class="bold">8. Warranty Disclaimer.</p>
                <p>8.1 THE SERVICES PROVIDED ARE &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDERS ASSURES OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. &nbsp;NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT {{this.$global.companyrootname}} WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE. &nbsp;</p>
                <p><br></p>
                <p class="bold">9. Indemnity.</p>
                <p>9.1 Customer shall defend, indemnify, and hold {{this.$global.companyrootnameshort}}, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to this Agreement, including, without limitation, any claims relating to the Services and Customer Content.</p>
                <p>9.2 {{this.$global.companyrootnameshort}} shall defend, indemnify, and hold Customer harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties that arises from {{this.$global.companyrootnameshort}}&rsquo; provision of the Services in an allegedly unlawful manner or in violation of this Agreement.&nbsp;</p>
                <p>9.3 If a third party asserts a claim against Customer that the Services offered by {{this.$global.companyrootnameshort}} &nbsp;infringes a patent, copyright, or trademark, {{this.$global.companyrootnameshort}} will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by {{this.$global.companyrootnameshort}}, provided that Customer promptly (a) notifies {{this.$global.companyrootnameshort}} in writing of the claim, (b) supplies information requested by {{this.$global.companyrootnameshort}}, and (c) allows {{this.$global.companyrootnameshort}} to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
                <p>9.4 {{this.$global.companyrootnameshort}}&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by {{this.$global.companyrootnameshort}}; or (b) Customer&rsquo;s unauthorized modifications to the Services; or (c) Customer&rsquo;s own Customer Content.</p>
                <p>9.5 This Section describes Customer&rsquo;s sole remedy against {{this.$global.companyrootnameshort}} relating to third-party claims of patent, copyright, or trademark infringement.</p>
                <p><br></p>
                <p class="bold">10. Limitation of Liability.</p>
                <p>10.1 WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW, {{this.$global.companyrootname}}&apos;S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO {{this.$global.companyrootname}}, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
                <p>10.2 THE LIMITATIONS IN SECTION 10.1 WILL NOT APPLY TO: (1) EITHER PARTY&rsquo;S BREACH OF CONFIDENTIALITY; (2) THE PARTIES&rsquo; INDEMNIFICATION OBLIGATIONS; (3) CUSTOMER&rsquo;S VIOLATION OF SECTION 1 (SERVICES) OR SECTION 2 OF THIS AGREEMENT (CUSTOMER RESPONSIBILITIES); (4) CUSTOMER&rsquo;S INFRINGEMENT OF {{this.$global.companyrootnameshort}}&rsquo;S INTELLECTUAL PROPERTY OR MISAPPROPRIATION; (5) GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. &nbsp;</p>
                <p><br></p>
                <p class="bold">11. Privacy and Security.</p>
                <p>11.1 All access to and use of the Services and use of the Platform is subject to {{this.$global.companyrootnameshort}}&lsquo;s Privacy Policy (&ldquo;Privacy Policy&rdquo;), a current copy of which is available at https://{{this.$global.companyrootdomain}}/privacy-policy/. Customer agrees to comply with the current terms of the Privacy Policy. &nbsp;{{this.$global.companyrootnameshort}} may collect, use, share, and store data made available via the Platform in connection with the Services hereunder and in accordance with {{this.$global.companyrootnameshort}}&rsquo;s policies and applicable laws.</p>
                <p>11.2 Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
                <p>11.3 Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
                <p><br></p>
                <p class="bold">12. Changes.</p>
                <p>12.1 {{this.$global.companyrootnameshort}} may, at its reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability. {{this.$global.companyrootnameshort}}&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing, {{this.$global.companyrootnameshort}} is not required to modify the Services.</p>
                <p>12.2 {{this.$global.companyrootnameshort}} may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
                <p>12.3 Except as otherwise provided, all changes to this Agreement must be in writing and signed by both Parties.</p>
                <p><br></p>
                <p class="bold">13. Term and Termination.</p>
                <p>13.1 The term of this Agreement begins upon execution and continues until terminated as described below. Termination of this Agreement by either party automatically terminates all orders in the applicable Order Form.</p>
                <p>13.2 The terms of the Services are set forth in the Order Form. Services renew annually and automatically unless Customer provides written notice to {{this.$global.companyrootnameshort}} not to renew at least 60 calendar days prior to the annual renewal.</p>
                <p>13.3 {{this.$global.companyrootnameshort}} may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer&apos;s use of the Services or this Agreement where {{this.$global.companyrootnameshort}} reasonably suspects a breach of this Agreement. {{this.$global.companyrootnameshort}} shall provide notice of the actions Customer must take to reinstate the Services. {{this.$global.companyrootnameshort}} may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
                <p>13.4 {{this.$global.companyrootnameshort}} may terminate an order or this Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
                <p>13.5 This Agreement shall automatically terminate upon the liquidation or insolvency of either Party or the appointment of a trustee or receiver for either Party.</p>
                <p>13.6 Upon termination of this Agreement or order described in the applicable Order Form: (a) {{this.$global.companyrootname}} may immediately terminate Customer&rsquo;s access to the Services; and (b) Customer shall immediately cease using any portion of the Services.</p>
                <p>13.7 Customer may not terminate this Agreement for convenience, except as otherwise stated in this Agreement. Either party may terminate this Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay {{this.$global.companyrootnameshort}} constitutes a material breach of this Agreement.</p>
                <p>13.8 If this Agreement is terminated for any reason (except for a material breach by {{this.$global.companyrootnameshort}}), Customer shall pay to {{this.$global.companyrootnameshort}}, on the date of termination, the total amount outstanding under this Agreement, including any fees for any remaining time period of a subscription or license. If {{this.$global.companyrootnameshort}} terminates this Agreement for a reason other than Customer&rsquo;s material breach, or if this Agreement is terminated for {{this.$global.companyrootnameshort}}&rsquo;s material breach, {{this.$global.companyrootnameshort}} shall provide Customer a pro rata refund for any prepaid and unearned fees.</p>
                <p>13.9 After termination of this Agreement, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer. {{this.$global.companyrootnameshort}} may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
                <p>13.10 After termination of this Agreement, except as otherwise provided in the applicable Order Form, {{this.$global.companyrootnameshort}} may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants {{this.$global.companyrootnameshort}} a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve {{this.$global.companyrootnameshort}}&rsquo;s Services or for its business purposes.</p>
                <p><br></p>
                <p class="bold">14. Governing Law.</p>
                <p>14.1 This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
                <p>14.2 Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under this Agreement.</p>
                <p>14.3 Each Party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
                <p><br></p>
                <p class="bold">15. Audit.</p>
                <p>15.1 Customer hereby agrees that upon reasonable notice and at a mutually agreeable time, {{this.$global.companyrootnameshort}} may periodically audit Customer&rsquo;s books and records relevant to the use of the Services in this Agreement in order to ensure compliance with the terms hereof. The third-party data providers of {{this.$global.companyrootnameshort}} shall be deemed intended third-party beneficiaries of this provision and shall also be entitled to investigate all legitimate reports of abuse or misuse of their Service. Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Services, legal action, and/or referral to federal or state regulatory agencies.</p>
                <p><br></p>
                <p class="bold">16. General.</p>
                <p>16.1 This Agreement constitutes the entire agreement of the Parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the Parties relating to its subject matter.</p>
                <p>16.2 In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order Form and this Agreement, the Order Form governs.</p>
                <p>16.3 If any provision of this Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
                <p>16.4 The waiver of a breach of any term of this Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of this Agreement.</p>
                <p>16.5 Customer and {{this.$global.companyrootnameshort}} agree not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, client, customer, supplier, or contractor of either Party during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
                <p>16.6 {{this.$global.companyrootnameshort}} is an independent contractor. Customer is responsible for its use of the Services. Each Party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
                <p>16.7 {{this.$global.companyrootnameshort}} may collect information relating to Customer&rsquo;s use of the Services. {{this.$global.companyrootnameshort}} may internally use this information for providing or improving the Services.</p>
                <p>16.8 Except as otherwise provided herein, Customer may not assign this Agreement, in whole or in part, without the prior written consent of {{this.$global.companyrootnameshort}}. Customer may assign this Agreement with 30 calendar days&rsquo; prior written notice to {{this.$global.companyrootnameshort}} upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of {{this.$global.companyrootnameshort}}. {{this.$global.companyrootnameshort}} may assign this Agreement at its sole discretion. Any assignment, transfer, or delegation of this Agreement in violation of this section is void.</p>
                <p>16.9 All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement, unless a Party designates a different address in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid). Notices must be sent to the respective parties at the following addresses:</p>
                <p><strong>{{this.$global.companyrootname}}</strong></p>
                <p>Email: {{this.$global.userrootemail}}</p>
                <p>Attention: {{this.$global.userrootname}}</p>
                <p>Address: {{this.$global.userrootaddres}} {{this.$global.userrootcity}} {{this.$global.userrootstatecode}} {{this.$global.userrootzip}}</p>
                <p><strong>{{CompanyName}}</strong></p>
                <p>Email: {{(this.$global.globalClient.companyemail == "")?user.company_email:this.$global.globalClient.companyemail}}</p>
                <p>Attention: {{CustomerName}}</p>
                <p>Address: {{(this.$global.globalClient.companyfulladdress == "")?CustomerAddress:this.$global.globalClient.companyfulladdress}}</p>
                <p>16.10 This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each Party and delivered to the other Party.</p>
                <p>16.11 The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement “I Agree”, pursuant to the Ohio Uniform Electronic Transactions Act (R.C. 1306.01 et seq.) as amended from time to time. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
                <p>16.12 This Agreement does not create any third-party rights. Neither Party will bring a legal action arising from, or relating to this Agreement, more than two years after the cause of action arose.</p>
                <p>16.13 Any terms that by their nature extend beyond this Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
                <p>16.14 Unless Customer requests otherwise in writing in advance, Customer authorizes {{this.$global.companyrootnameshort}} to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
                <p>16.15 The Parties hereto are not responsible for failure to fulfill its obligations under this Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
                <p>16.16 The Parties hereto are sophisticated, commercial Parties. This Agreement will not be construed against the drafter.</p>
                <p>16.17 Parties acknowledge that they have read this Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each Party is authorized to do so.</p>
                <p><br></p>
                <p><br></p>
                <p class="bold"><a href="/privacy-policy/" target="_blank">EXHIBIT A</a></p>
                <p><br></p>
                <p class="bold"><a href="/term-of-use/" target="_blank">EXHIBIT B</a></p>
          </div>

          <div class="container" v-if="platformagency">
                <p class="bold text-center">Marketing Services Agreement</p>
                <p><br></p>
                <p><br></p>
                <p>This Marketing Agreement (“Marketing Agreement”) and applicable attachments or orders (“Order”) are the complete agreement regarding transactions under this agreement (together, the “Agreement”) under which Customer may order Services. Orders detail the specifics of transactions, such as charges and a description of the Services.</p>
                <p><br></p>
                <p class="bold">1. Services.</p>
                <p>1.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;In consideration for the payment of the subscription payments made in accordance with the applicable Order,&nbsp;{{CompanyName}}&nbsp;hereby provides the Services to Customer.</p>
                <p>1.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Services&rdquo; means&nbsp;{{CompanyName}}&rsquo;s subscription services made available via the internet as described in an Order. Services include lead generation, marketing and advertising, data, data cleansing, documents, or other materials that&nbsp;{{CompanyName}}&nbsp;provides to Customer (&ldquo;Materials&rdquo;).</p>
                <p>1.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means employees (and any independent contractors performing functions comparable to employees in the ordinary course of business) of Customer and its affiliates who: (a) are authorized by&nbsp;{{CompanyName}}; (b) are bound by the Agreement; and (c) have their own subscription.</p>
                <p>1.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may access the Services only to the extent authorized by&nbsp;{{CompanyName}}. Customer is responsible for use of Services by its users, including its Authorized Users. Customer shall ensure that Authorized Users comply with&nbsp;{{CompanyName}}&rsquo;s <a :href="'' + CompanyTermsofuse" target="_blank">Terms of Use</a>)&nbsp;and  <a :href="'' + CompanyPrivacyPolicy" target="_blank">Privacy Policy</a>)&nbsp;which are incorporated herein by reference, when using the Services. Customer is responsible for its actions and the contents of its transmissions through the Services. Customer is responsible for the compliance of Customer Content with this Agreement, including content uploaded by its users.</p>
                <p>1.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not: (a) duplicate, disassemble, reverse engineer, or otherwise reproduce without authorization any portion of the Services; (b) resell direct access to the Services to a third party; (c) scrape, steal, or copy without authorization Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party Services in violation of this Agreement; or (f) build a product, service, or offering that competes with&nbsp;{{CompanyName}}&nbsp;or Services.</p>
                <p>1.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses, spyware or similar computer programming routines, or unsolicited mail; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
                <p><br></p>
                <p class="bold">2. Customer Responsibilities.</p>
                <p>2.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Agreement and Services are a valuable trade secret and confidential proprietary property of&nbsp;{{CompanyName}}. Customer agrees to access and use&nbsp;{{CompanyName}}&rsquo;s Services only as provided in this Agreement and to safeguard&nbsp;{{CompanyName}}&rsquo;s trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed in writing.</p>
                <p>2.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is hereby provided with limited access to the Services subject to the Agreement. Subject to the terms of the Agreement,&nbsp;{{CompanyName}}&nbsp;hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term of the applicable Order to access and use the Materials for its business purposes. Except as otherwise stated in this section or the Order, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s license to use the Materials is only valid for six (6) months from purchase (or less if required by&nbsp;{{CompanyName}}&nbsp;data providers, as set forth in more detail in the Order).</p>
                <p>2.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
                <p>2.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Customer Content&rdquo; means all content or information that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order, Customer hereby grants&nbsp;{{CompanyName}}&nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve&nbsp;{{CompanyName}}&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
                <p>2.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to,&nbsp;{{CompanyName}}, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to&nbsp;{{CompanyName}}.</p>
                <p>2.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; and (c) complying with applicable law.</p>
                <h1>3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Charges, Taxes, and Payment.</h1>
                <p>3.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer agrees to pay all applicable charges specified for the Services and any charges imposed by any authority resulting from Customer&apos;s use of the Services, including any applicable sales taxes. Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and any Order. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
                <p>3.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in the Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in Section 10.2.</p>
                <p>3.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date.&nbsp;{{CompanyName}}&nbsp;may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
                <p><br></p>
                <p class="bold">4. Confidentiality.</p>
                <p>4.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of the Agreement.</p>
                <p>4.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify&nbsp;{{CompanyName}}&nbsp;in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
                <p>4.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;{{CompanyName}}&nbsp;shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with the Agreement and its policies.</p>
                <p><br></p>
                <p class="bold">5. Warranties.</p>
                <p>5.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Each party warrants to the other that this Agreement been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
                <p><br></p>
                <p class="bold">6. Warranty Disclaimer.</p>
                <p>6.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;THE SERVICE IS PROVIDED &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. &nbsp;BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDERS ASSURES&nbsp;OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT&nbsp;{{CompanyName}}&nbsp;WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE. &nbsp;</p>
                <p><br></p>
                <p class="bold">7. Indemnity.</p>
                <p>7.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall defend, indemnify, and hold&nbsp;{{CompanyName}}, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to the Agreement, including, without limitation, any claims relating to the Services.</p>
                <p>7.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If a third party asserts a claim against Customer that services offered by  {{CompanyName}} infringe a patent, copyright, or trademark,&nbsp;{{CompanyName}}&nbsp;will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by&nbsp;{{CompanyName}}, provided that Customer promptly (a) notifies&nbsp;{{CompanyName}}&nbsp;in writing of the claim, (b) supplies information requested by&nbsp;{{CompanyName}}, and (c) allows&nbsp;{{CompanyName}}&nbsp;to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
                <p>7.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by&nbsp;{{CompanyName}}; or (b) Customer&rsquo;s unauthorized modifications to the Services.</p>
                <p>7.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Section 7 describes Customer&rsquo;s sole remedy against&nbsp;{{CompanyName}}&nbsp;relating to third-party claims of patent, copyright, or trademark infringement.</p>
                <p><br></p>
                <p class="bold">8. Limitation of Liability.</p>
                <p>8.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW,&nbsp;{{CompanyName}}&apos;S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THE AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO&nbsp;{{CompanyName}}, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
                <p><br></p>
                <p class="bold">9. Privacy and Security.</p>
                <p>9.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;shall use commercially reasonable efforts comply with its Privacy Policy.</p>
                <p>9.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
                <p>9.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
                <p><br></p>
                <p class="bold">10. Changes.</p>
                <p>10.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may, at&nbsp;{{CompanyName}}&apos;s reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability.&nbsp;{{CompanyName}}&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing,&nbsp;{{CompanyName}}&nbsp;is not required to modify the Services.</p>
                <p>10.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
                <p>10.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Except as otherwise provided, all changes to the Agreement must be in writing and signed by both parties.</p>
                <p><br></p>
                <p class="bold">11. Term and Termination.</p>
                <p>11.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The term of the SaaS Agreement begins upon execution and continues until terminated as described below. Termination of this SaaS Agreement by either party automatically terminates all Orders.</p>
                <p>11.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The term of Services is set forth in the Order. Services renew annually automatically unless Customer provides written notice to&nbsp;{{CompanyName}}&nbsp;not to renew at least 60 calendar days prior to the annual renewal.</p>
                <p>11.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer&apos;s use of the Services or the Agreement where&nbsp;{{CompanyName}}&nbsp;reasonably suspects a breach of the Agreement.&nbsp;{{CompanyName}}&nbsp;shall provide notice of the actions Customer must take to reinstate the Services.&nbsp;{{CompanyName}}&nbsp;may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
                <p>11.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may terminate an Order or the Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
                <p>11.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Agreement shall automatically terminate upon the liquidation or insolvency of either party or the appointment of a trustee or receiver for either party.</p>
                <p>11.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Upon termination of the SaaS Agreement or applicable Order: (a)&nbsp;{{CompanyName}}&nbsp;may immediately terminate Customer&rsquo;s access to&nbsp;{{CompanyName}}&rsquo;s Services; and (b) Customer shall immediately cease using any portion of&nbsp;{{CompanyName}}&rsquo;s Services.</p>
                <p>11.7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not terminate the Agreement for convenience, except as otherwise stated in Section 10.2. Either party may terminate the Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay&nbsp;{{CompanyName}}&nbsp;is a material breach.</p>
                <p>11.8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If the Agreement is terminated for any reason (except for material breach by&nbsp;{{CompanyName}}), Customer shall pay to&nbsp;{{CompanyName}}, on the date of termination, the total amount outstanding under the Agreement. If&nbsp;{{CompanyName}}&nbsp;terminates for a reason other than Customer&rsquo;s material breach of the Agreement or if the Agreement is terminated for material breach by&nbsp;{{CompanyName}},&nbsp;{{CompanyName}}&nbsp;shall provide Customer a pro rata refund for any prepaid fees.</p>
                <p>11.9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;After termination, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer.&nbsp;{{CompanyName}}&nbsp;may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
                <p>11.10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;After termination, except as otherwise provided in an applicable Order,&nbsp;{{CompanyName}}&nbsp;may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants&nbsp;{{CompanyName}}&nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve&nbsp;{{CompanyName}}&rsquo;s Services or for its business purposes.</p>
                <p><br></p>
                <p class="bold">12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Governing Law.</p>
                <p>12.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. &nbsp;The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
                <p>12.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under the Agreement.</p>
                <p>12.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Each party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
                <p><br></p>
                <p class="bold">13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Audit. &nbsp;Customer hereby agrees that upon reasonable notice and at a mutually agreeable time,&nbsp;{{CompanyName}}&nbsp;may periodically audit Customer&rsquo;s books and records relevant to the use of this Service in order to ensure compliance with the terms hereof. &nbsp;The third-party data providers of&nbsp;{{CompanyName}}&nbsp;shall be deemed intended third-party beneficiaries of this provision and shall also be entitled investigate all legitimate reports of abuse or misuse of their Service. &nbsp;Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Service, legal action, and/or referral to federal or state regulatory agencies.</p>
                <p class="bold">14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;General.</p>
                <p>14.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement constitutes the entire agreement of the parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the parties relating to its subject matter.</p>
                <p>14.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order and the Agreement, the Order governs.</p>
                <p>14.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If any provision of the Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
                <p>14.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The waiver of a breach of any term of the Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of the Agreement.</p>
                <p>14.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer agrees not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, supplier, or contractor of&nbsp;{{CompanyName}}&nbsp;during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
                <p>14.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;is an independent contractor. Customer is responsible for its use of&nbsp;{{CompanyName}}&nbsp;Services. Each party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
                <p>14.7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may collect information relating to Customer&rsquo;s use of the Services.&nbsp;{{CompanyName}}&nbsp;may internally use this information for providing or improving the Services.</p>
                <p>14.8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Except as otherwise provided herein, Customer may not assign the Agreement, in whole or in part, without the prior written consent of&nbsp;{{CompanyName}}. Customer may assign the Agreement with 30 calendar days&rsquo; prior written notice to&nbsp;{{CompanyName}}&nbsp;upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of&nbsp;{{CompanyName}}.&nbsp;{{CompanyName}}&nbsp;may assign the Agreement at its sole discretion. Any assignment, transfer, or delegation in violation of this section is void.</p>
                <p>14.9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement, unless a Party designates a different address in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid). Notices must be sent to the respective parties at the following addresses:</p>
                <p><strong>{{CompanyName}}</strong></p>
                <p>Email: {{CompanyEmail}}</p>
                <p>Attention: {{CustomerCareName}}</p>
                <p>Address: {{CompanyAddress}}</p>
                <p><strong>Customer:</strong> {{(this.$global.globalClient.companyname == "")?user.company_name:this.$global.globalClient.companyname}}</p>
                <p>Email: {{(this.$global.globalClient.companyemail == "")?user.company_email:this.$global.globalClient.companyemail}}</p>
                <p>Attention: {{(this.$global.globalClient.name == "")?user.name:this.$global.globalClient.name}}</p>
                <p>Address: {{(this.$global.globalClient.fulladdress == "")?CustomerAddress:this.$global.globalClient.fulladdress}}</p>
                <p>14.10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each party and delivered to the other party.</p>
                <p>14.11.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement “I Agree”. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
                <p>14.12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement does not create any third-party rights. Neither party will bring a legal action arising from or relating to the Agreement more than two years after the cause of action arose.</p>
                <p>14.13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Any terms that by their nature extend beyond the Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
                <p>14.14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Unless Customer requests otherwise in writing in advance, Customer authorizes {{CompanyName}} to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
                <p>14.15.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Neither party is not responsible for failure to fulfill its obligations under the Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
                <p>14.16.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The parties hereto are sophisticated, commercial parties. The Agreement will not be construed against the drafter.</p>
                <p>14.17. &nbsp; &nbsp; &nbsp; &nbsp; Parties acknowledge that they have read the Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each party is authorized to do so.</p>
        </div>
            <!-- SERVICE BILLING AGREEMENT -->
          </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 ">
                <base-checkbox :disabled="agreeTermDisable" v-model="agreeTermBilling" class="pull-left" :class="{'has-danger': agreeTermStatBilling}">I read and Agree with Service and Billing Agreement</base-checkbox> 
        </div>
         <div class="col-sm-12 col-md-12 col-lg-12 " style="padding-top:15px">
           <a href="/service-billing-agreement/" target="_blank" style="font-weight:bold;text-decoration:underline">Click here to view service and billing agreement page</a>  
         </div>
        <!--<div class="col-sm-12 col-md-12 col-lg-12 ">
          <base-checkbox :disabled="agreeTermDisable" v-model="agreeTerm" class="pull-left" :class="{'has-danger': agreeTermStat}" >I Agree with <a href="#termofbilling" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[agreeTermStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.termofbilling = true">Term of Billing</a></base-checkbox>
        </div>-->
      </div>
    </form>
   <!-- MODAL ERROR WARNING-->
    <modal :show.sync="modals.errorbilling" headerClasses="justify-content-center" modalContentClasses="modal-errorbilling">
      <h4 slot="header" class="title title-up">Error</h4>
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
          <span class="swal2-x-mark-line-left"></span>
          <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <h2 class="swal2-title" id="swal2-title" style="display: block;">Fields marked in red must be filled in completely.</h2>
      <div class="swal2-html-container text-center" id="swal2-html-container" style="display: block;">&nbsp;</div>
      <template slot="footer">
        <div class="container text-center pt-4 pb-4">
          <base-button class="btn btn-secondary" style="min-width:200px" @click="modals.errorbilling = false;">Ok</base-button>
        </div>
      </template>
    </modal>
    <!-- MODAL ERROR WARNING-->

    <!-- MODAL TERM OF BILLING-->
    <modal :show.sync="modals.termofbilling" headerClasses="justify-content-center" id="modaltermofbilling">
              <h4 slot="header" class="title title-up">TERMS of Billing</h4>
              <div class="termofbilling">
                <p>Revised: April 1, 2022</p>
                <ul style="list-style-type:decimal">
                  <li>Acceptance of Terms.
                    <p><strong>By using this Website, you agree to the Terms of Use</strong> (the “Terms”).</p>
                    <p>The use of “we,” “our,” and “us” herein refers to the {{CompanyName}} Marketing, LLC, and “you” and “your” refers to you as the consumer utilizing this Website (“Website”). We maintain this Website to offer Advertising, Marketing and Lead Generation services, among other things.</p>
                    <p>Please read these Terms carefully, as they create a legally binding agreement between you and us regarding your use of the Website. By using the Website, you are agreeing to be legally bound by these Terms. <strong>If you do not agree to follow these Terms, do not use the Website.</strong></p>
                    <p>Your use of this Website is subject to our Privacy Policy, which is hereby incorporated into the Terms by this reference.</p>
                  </li>
                  <li>Using the Website; Authorized Uses.
                    <p>You should only use the Website if you are authorized or it is otherwise legal for you to do so. You agree these Terms and our Privacy Policy govern your use of the Website and the services we offer you through the Website. You also agree to abide by all applicable local, state, federal, and international laws and regulations while using the Website. If you breach these Terms, you are solely responsible for any damages that result therefrom. You are solely responsible for your actions while using the Website and any damages that result. You are responsible for any software, information, data, files, images, documents, text or other material you post, submit or transmit through the Website (“Materials”), including for any damages resulting therefrom.</p>
                  </li>
                  <li>User Submissions.
                    <p>If you post, submit, or transmit Materials on or through the Website, you warrant that:</p>
                      <blockquote>
                        <p>a) The Materials contain truthful, accurate, and complete information and you agree to update information as is necessary to make sure it remains truthful, accurate, and complete;</p>
                        <p>b) You own or control the rights to the Materials and the Materials do not contain copyrighted or protected information;</p>
                        <p>c) You are liable for damages caused by infringement and agree to indemnify us for any third-party damages resulting from such infringement;</p>
                        <p>d) The Materials do not violate the Terms of Use and will not injure another person; and</p>
                        <p>e) Materials posted, submitted, and transmitted are not confidential.</p>
                      </blockquote>
                    
                    <p>You are solely liable for any damages resulting from Materials you post, submit or transmit through the Website. We disclaim all liability for user posts, submissions and transmission on the Website. We do not ratify, subscribe to, or advocate for your views, opinions, recommendations, advice, or theories. We do not preview Materials posted, submitted, or transmitted through the Website. We may monitor, edit, or remove Materials and/or terminate your access to the Website or any part of the Website based on the Materials you post, submit or transmit.</p>
                    <p>By posting, submitting, or transmitting Materials on or through the Website, you grant us an  irrevocable, perpetual, non-exclusive, royalty free, worldwide license to use, sublicense, reproduce, adapt, modify, translate, publish, distribute, perform, or publicly display all or any portion of the Materials, subject to our Privacy Policy, without attributing the Materials to you. You agree that we are not bound by any confidentiality requirements for these Materials.</p>
                    <p>We may use the Materials you post, submit, or transmit and any techniques, ideas, or concepts found therein, in any way and for any purpose. We may transmit or distribute the Materials on public networks or other media, and make such Materials available to other companies, organizations, or individuals. We may make changes to Materials as necessary to conform or adapt Materials to technical requirements of public networks or media.</p>
                  </li>
                  <li>Intellectual Property Rights.
                    <p>This Website contains our intellectual property. In some instances, the Website may contain the intellectual property of third parties. All intellectual property is protected by U.S. and international copyright law and other intellectual property laws. You may not reproduce or distribute the Website content in any way without our express written consent. Doing so may result in civil and criminal penalties, and you will be prosecuted to the maximum extent possible under law. The publication or dissemination of any link to the contents of this Website, other than a link to <a href="https://exactmatchmarketing.com" target="_blank">https://exactmatchmarketing.com</a>, without our express written consent is prohibited.</p>
                    <p>We grant you a personal, non-transferable, non-exclusive, limited, and freely revocable license to view, download, or print content available on the Website. However, we may limit your ability to access, view, download, or print content at our sole discretion, or completely revoke this license with reasonable notice to you. The content you access, view, download, or print may be subject to other licenses and you agree to abide by those licenses.</p>
                    <p>Nothing stated herein shall be construed to confer any rights to our (or any third party’s rights to) intellectual property, whether by estoppel, implication, waiver, or otherwise, except where expressly stated. You agree not to change, remove, or deface any of the copyright notices, trademarks, service marks, or other intellectual property made available by us in connection with this Website, including if you download or print content from the Website. You agree not to use any of the trademarks, service marks, or other content accessible through the Website for any purpose not intended by us. You agree not to use any of the trademarks, service marks, or other content accessible through the Website in any manner that is likely to confuse others about who owns such trademarks, service marks, or other content. You agree not to defame or disparage us, our trademarks or service marks, or any aspect of this Website. Unless otherwise stated, you agree not to copy, adapt, change, translate, decompile, disassemble, reverse engineer, or create derivative works of this Website, any software or other features used in connection with or made available through your use of this Website.</p>
                  </li>
                  <li>Copyright Infringement Notification.
                    <p>If you believe that any part of our Website or any content thereon infringes on another’s copyright, please notify us, in writing, with the following information: </p>
                         <blockquote>
                           <p>1) Identification of the copyrighted work claimed to have been infringed, or identification of a representative list of such works if multiple copyrighted works are involved;</p>
                           <p>2) Description of the location of an original or an authorized copy of the copyrighted work (i.e., a URL);</p>
                           <p>3) Description of the location of the allegedly infringing material on our Website (i.e., a URL);</p>
                           <p>4) Your contact information, including your address, telephone number, and an electronic mail address, if available;</p>
                           <p>5) A statement by you that you believe, in good faith, that the use of the material at issue is not authorized by the copyright owner, its agent, or the law;</p>
                           <p>6) A statement by you, under penalty of perjury, that the information you are providing is accurate and that you are either the copyright owner or that you are authorized to act for the copyright owner; and</p>
                           <p>7) The signature (physical or electronic) of the copyright owner or the person authorized to act for the copyright owner.</p>
                         </blockquote>
                   
                    <p>Please send your written communication to:</p>
                    <p>{{CompanyName}} Marketing, LLC</p>
                    <p>copyright(at)exactmatchmarketing.com</p>
                    <p>If you knowingly and materially misrepresent that our Website content or activity infringes upon another’s copyright, you may be liable for damages, including for costs and attorneys’ fees incurred by the accused infringer, the copyright owner or their agent, or a service provider or other person who is damaged therefrom, due to reliance on the misrepresentation, the removal or disablement of the allegedly infringing material, or the replacement of the allegedly infringing material, pursuant to 17 U.S.C. § 512(f).</p>
                    <p>This information is provided as a courtesy and should not be considered legal advice.</p>
                  </li>
                </ul>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button @click="agreedtermofbilling();">Yes, I Understand</base-button>
                </div>
              </template>
            </modal>
      <!-- MODAL TERM OF BILLING -->
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Modal,BaseCheckbox,BaseRadio } from '/src/components';
import BaseInput from '../../../../../../components/Inputs/BaseInput.vue';

var _elements;
var _cardElement;
var _stripe;
var _CardValidate;
var _tokenid = '';
var _sppubkey = '';
var _this;

export default {
  components: {
    Modal,
    BaseCheckbox,
    [Option.name]: Option,
    [Select.name]: Select,
    BaseRadio,
  },
  data() {
    BaseInput
    return {
      agencyManualBill:false,
      showErrorMessage:false,
      cardfailetoload:false,
      CompanyName: 'Exact Match Marketing',
      CompanyAddress: '',
      CompanyPrivacyPolicy: '',
      CompanyTermsofuse: '',
      CompanyWeb: '',
      CompanyEmail: '',
      CustomerCareEmail: '',
      CustomerCareName: '',
      CustomerCompanyName: '',
      CustomerCompanyEmail: '',
      CustomerEmail: '',
      CustomerName: '',
      CustomerAddress: '',
      user: '',

      cardholdername: '',
      billingaddress: '',
      city: '',
      zipcode: '',
      selects: {
        state: '',
        country: '',
        statelist: [],
      },
      ccmonth:'',
      ccyear:'',
      radios: {
        billaddresssame:'',
      },
      modals: {
        termofbilling:false,
        errorbilling:false,
      },
      agreeTermDisable:true,
      agreeTermBilling:false,
      agreeTermStatBilling:false,
      agreeTerm:false,
      agreeTermStat:false,

      platform: false,
      platformagency: false,
      emmplatform:false,
      intervalId: null,
    };
  },
  methods: {
    refreshCCfield() {
      if (this.$global.sppubkey != "") {
        _sppubkey = this.$global.sppubkey;
        this.initstripelib();
        //this.cardfailetoload = false;
      }else{
        this.$store.dispatch('getGeneralSetting', {
              companyID: this.user.company_root_id,
              settingname: 'rootstripe',
          }).then(response => {
            _sppubkey = response.data;
            this.initstripelib();
            //this.cardfailetoload = false;
          },error => {
            this.$notify({
              type: 'primary',
              message: 'Please try to refresh the page',
              icon: 'fas fa-bug'
            })
            this.cardfailetoload = true;    

          });
      }
    },
    agreedtermofbilling() {
      this.agreeTerm = true;
      this.agreeTermDisable = false;
      this.modals.termofbilling = false;
    },
    termscrolltop() {
      this.$refs.termsandcond.scrollTop = 0;
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      //console.log(Math.ceil(scrollTop) + ' | ' + clientHeight + ' | ' + scrollHeight);
      if (Math.ceil(scrollTop) + (clientHeight + 20) >= scrollHeight) {
        this.agreeTermDisable = false;
      }
    },
    initstripelib() {
      var chkload = this.$global.stripescriptload;
      if (chkload === false) {
        this.$global.initStripeScriptLoad(true);
        $.getScript( "https://js.stripe.com/v3/" )
        .done(function( script, textStatus ) {
            initcreditcard();
        })
        .fail(function( jqxhr, settings, exception ) {
            //$( "div.log" ).text( "Triggered ajaxError handler." );
        });
      }else{
        initcreditcard();
      }
      
    },
    chksameaddresswith() {
      
      if (this.radios.billaddresssame == "sameCompanyAddr") {
        this.billingaddress = this.$store.getters.userData.company_address
        this.city = this.$store.getters.userData.company_city
        this.selects.state = this.$store.getters.userData.company_state_code
        this.selects.country = this.$store.getters.userData.company_country_code
        this.zipcode = this.$store.getters.userData.company_zip
      }else{
        this.billingaddress = this.$store.getters.userData.address
        this.city = this.$store.getters.userData.city
        this.selects.state = this.$store.getters.userData.state_code
        this.selects.country = this.$store.getters.userData.country_code
        this.zipcode = this.$store.getters.userData.zip
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    makeFinishSetup() {
      var user = this.$store.getters.userData
      this.$store.dispatch('userSetupComplete', {
          usrID: user.id,
          statuscomplete: 'T',
      }).then(response => {
          //console.log(response.params)
          /*this.$store.dispatch('setUserData', {
              user: response.params,
          });*/
          this.$store.dispatch('retrieveUserData',{
                usrID: user.id,
          });
          this.$global.creditcardsetup = true;
      },error => {
          
      });
    },
    ccsetupfinish() {
      var user = this.$store.getters.userData
      this.$store.dispatch('retrieveUserData',{
                usrID: user.id,
      });
      this.$global.creditcardsetup = true;
    },
    showErrorModal() {
      this.modals.errorbilling = true;
    },
    createStripeCustomer() {
      var _store = this.$store;
      var _this = this;
      var _user = this.$store.getters.userData
      return new Promise((resolve, reject) => {
        _stripe.createToken(_cardElement).then(function(result) {
            if(result.error) {
                $('.btnNext2').html('Next');
                $('.btnNext2').attr('disabled',false);
                console.log(result.error.message);
                $('#carderror').html('<small style="color:red"><sup>*</sup>' + result.error.message + '</small>');
                $('#carderror').show();
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
                _this.showErrorModal();
            }else{
                $('#carderror').hide();
                _tokenid = result.token.id;
                _store.dispatch('paymentcustomersetup', {
                    tokenid: _tokenid,
                    cardholder: _this.cardholdername,
                    address: _this.billingaddress,
                    city: _this.city,
                    state: _this.selects.state,
                    country: _this.selects.country,
                    zip: _this.zipcode,
                    usrID: _user.id,
                    action: '',
                }).then(response => {
                    //console.log(response.result)
                    if(response.result == 'success') {
                      //_this.makeFinishSetup();
                      _this.ccsetupfinish();
                      if (_user.user_type == 'client') {
                        $('.btnNext2').html('Go To Dashboard');
                      }else{
                        $('.btnNext2').html('Go To General Settings');
                      }
                      $('.btnNext2').attr('disabled',false);
                      $('html, body').animate({
                          scrollTop: 0
                      }, 500);
                      resolve(true);   
                    }
                },error => {
                    $('.btnNext2').html('Next');
                    $('.btnNext2').attr('disabled',false);
                    $('#carderror').html('<small style="color:red"><sup>*</sup>The credit card has expired, reached its limit, or is not being authorized as a valid credit card</small>');
                    $('#carderror').show();
                    $('html, body').animate({
                        scrollTop: 0
                    }, 500);
                     _this.showErrorModal();
                });
            }
        });
      });
      /*token = _stripe.createToken(_cardElement).then(function(result) {
            if(result.error) {
                console.log(result.error.message);
            }else{
              _tokenid = result.token.id;
              //console.log('Token : ' + _tokenid);
                _store.dispatch('paymentcustomersetup', {
                    tokenid: _tokenid,
                }).then(response => {
                    //console.log(response.result)
                    if(response.result == 'success') {
                      //_this.makeFinishSetup();
                     
                      
                    }
                },error => {
                    
                });

            }
      });*/
    },
    getStateList() {
      this.$store.dispatch('getStateList').then(response => {
          this.selects.statelist = response.params
      },error => {
          
      });
    },
    validate() {
      if (this.user.user_type != 'client' || (this.user.manual_bill == 'F' && this.user.user_type == 'client')) {
        if (typeof(_cardElement) == "undefined" || _sppubkey == "") {
          this.cardfailetoload = true;
          return false;
        }

        if (this.selects.country == '') {
                this.$notify({
                  type: 'primary',
                  message: 'Please select country',
                  icon: 'fas fa-bug'
                })
                this.showErrorMessage = true;
              }
        if (this.selects.state == '') {
          this.$notify({
            type: 'primary',
            message: 'Please select state',
            icon: 'fas fa-bug'
          })
          this.showErrorMessage = true;
          // $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-danger');
          // $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-success');
        }else{
          $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
          $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
        }

        /*this.agreeTermStat = false;
        if (this.agreeTerm == false) {
            this.agreeTermStat = true;
        }*/
        this.agreeTermStatBilling = false;
        if (this.agreeTermBilling == false) {
          this.agreeTermStatBilling = true;
        }

        return this.$refs.form.validate().then(res => {
          if(res) {
            this.agreeTermStatBilling = false;
            if (this.agreeTermBilling == false) {
              this.agreeTermStatBilling = true;
              $('html, body').animate({
                  scrollTop: 0
              }, 500);
              this.modals.errorbilling = true;
              return false;
            }
            
            /*this.agreeTermStat = false;
            if (this.agreeTerm == false) {
              this.agreeTermStat = true;
              return false;
            }*/
            if (this.selects.state !== '' && this.selects.country != '' && (typeof(_cardElement) != "undefined" && _sppubkey != "")) {
              //this.createStripeCustomer();
              $('.btnNext2').html('Processing...')
              $('.btnNext2').attr('disabled',true);
              this.$emit("on-validated", res,this.$data);
              return this.createStripeCustomer();
            }else{
              this.showErrorMessage = true;
            }
            
            /*this.makeFinishSetup();
            this.$emit("on-validated", res,this.$data);
            return res;
            */
          }else{
            //this.showpoperror();
            $('html, body').animate({
              scrollTop: 0
            }, 500);
            this.modals.errorbilling = true;
          }
        });
      }else{
            this.agreeTermStatBilling = false;
            if (this.agreeTermBilling == false) {
              this.agreeTermStatBilling = true;
            }
            
            if (this.agreeTermBilling) {
              $('.btnNext2').html('Processing...')
              $('.btnNext2').attr('disabled',true);
              return new Promise((resolve, reject) => {
                  this.$store.dispatch('paymentdirectcustomersetup', { 
                        usrID: this.user.id,
                  }).then(response => {
                        //console.log(response.result)
                        if(response.result == 'success') {
                          this.ccsetupfinish();
                          if (this.user.user_type == 'client') {
                            $('.btnNext2').html('Go To Dashboard');
                          }
                          $('.btnNext2').attr('disabled',false);
                        
                          $('html, body').animate({
                              scrollTop: 0
                          }, 500);
                          
                          resolve(true);
                        }
                    },error => {
                        
                    });
              });
            }
      }

    }
  },
  watch: {
    agreeTermBilling: function(value) {
      if (value == true) {
        this.agreeTermStatBilling = false;
      }
    },
    /*agreeTerm: function(value) {
      if (value == true) {
        this.agreeTermStat = false;
      }
    },*/
  },
  mounted() {
    this.user = this.$store.getters.userData
    if (this.user.manual_bill == 'T' && this.user.user_type == 'client') {
      this.agencyManualBill = true;
    }else{
      this.agencyManualBill = false;
    }
    //console.log(user);
    if (this.user.user_type == 'userdownline') {
       this.CompanyName = this.user.company_name;
       this.CompanyAddress = this.user.company_address + ', ' +  this.user.company_city + ', ' +  this.user.company_state_name + ' ' +  this.user.company_zip;
       this.CompanyEmail = this.user.company_email;
       this.CustomerName = this.user.name;
       this.CustomerEmail = this.user.email;
    }else{
      this.CompanyName = this.user.companyparentname;
      this.CompanyAddress = this.user.companyparentaddress + ', ' +  this.user.companyparentcity + ', ' +  this.user.companyparentstate + ' ' +  this.user.companyparentzip;
      this.CompanyEmail = this.user.companyparentemail;
      this.CustomerCareEmail = this.user.customercare;
      this.CustomerCareName = this.user.customercarename;
      this.CustomerCompanyName = this.user.company_name;
      this.CustomerCompanyEmail = this.user.company_email;
      this.CustomerName = this.user.name;
      this.CustomerAddress = this.user.address + ', ' +  this.user.city + ', ' +  this.user.state_name + ' ' +  this.user.zip;
    }

    this.CompanyWeb = document.location.hostname;
    this.CompanyPrivacyPolicy = 'https://' + this.CompanyWeb + '/privacy-policy';
    this.CompanyTermsofuse = 'https://' + this.CompanyWeb + '/term-of-use';
   
    if (typeof(this.user.user_type) == 'undefined' || this.user.user_type == 'client') {
       this.platformagency = true;
    }else{
      if(this.user.user_type == 'userdownline' && this.$global.agencyplatformroot == true) {
        this.emmplatform = true;
      }else{
        this.platform = true;
      }
    }

    _sppubkey = this.$global.sppubkey;
    _this = this;
    this.agreeTermDisable = true;
    this.cardfailetoload = false;
    if (typeof(_sppubkey) == "undefined" || _sppubkey == "") {
      this.cardfailetoload = true;
    }else{
      this.initstripelib();
    }
    this.getStateList();
    
  }
};

function initcreditcard() {
  if (!$('#card-element').length) {
    return
  }

	_stripe = Stripe(_sppubkey);
	/** ATTACHED CARD ELEMENTS */
   _elements = _stripe.elements();
    _cardElement = _elements.create('card', {
    hidePostalCode: true,   
    style: {
        base: {
               color: 'rgba(82, 95, 127, 0.8)',
                fontSize: '16px',
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
        },
    });
    _this.cardfailetoload = false;

    if (typeof(_cardElement) == "undefined") {
      _this.cardfailetoload = true;
    }
    //var cardElement = elements.create('card');
    _cardElement.mount('#card-element');
    /** ATTACHED CARD ELEMENTS */

    /** CARD ELEMENT VALIDATION */
    _cardElement.on('change', function(event) {
        if (event.error) {
          console.log('Card Error :' + event.error.message);
          $('#carderror').html('<small style="color:red"><sup>*</sup>' + event.error.message + '</small>');
          $('#carderror').show();
          //this.cardError = true;

          //_CardValidate = false;
        } else {
          $('#carderror').hide();
          //_CardValidate = true;
        }
    });

	
}
</script>
<style>
.modal-errorbilling {
  max-width: 520px;
  margin: 0 auto;
}
#modaltermofbilling .termofbilling {
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  /*border: 1px solid #525f7f;*/
  padding: 10px;
}

  .centerform {
    width: 90%;
    margin: 0 auto;
  }

@media (min-width: 1024px) {
  .centerform {
    width: 50%;
    margin: 0 auto;
  }
}
.ccmonth {
  width: 50% !important;
}
.ccyear {
  width: 50% !important;
  padding-left: 10px;
}
#usrsetupcc .card-border {
  max-width: 95%;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #525f7f;
  border-radius:4px;
  padding-top:8px;
  /*padding: 8px 12px 6px 12px;
  margin:8px 0px 4px -1px;
  */
  height:36px;
  box-sizing: border-box;
  transform: translateZ(0);
  -webkit-user-select:text;
}

.termsx {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  width: 100%;
  border: 1px solid #525f7f;
  padding: 10px;
  background-color: #FFF;
}

.termsx p, .termsx span {
  color: #525f7f !important;
}

.termsx a,.termsx a:hover {
  color: #942434 !important;
}

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/*border-radius: 10px;*/
	background-color: gray;
  opacity: 0.5;
}

#style-2::-webkit-scrollbar
{
	width: 12px;
	background-color: #525f7f;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #525f7f;
}

.card-wizard .disabled {
    display: block;
}

.termsx p.bold {
    font-weight: bold;
}

</style>
